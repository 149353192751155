<template>
  <span class='advertiser' @click='handleAdvertiser'>{{ advertiser }}</span>
</template>

<script>
export default {
  name: 'Advertiser',
  data() {
    return {
      showAd: true,
    };
  },
  computed: {
    advertiser() {
      return this.showAd ? 'Рекламодатель' : 'ООО «Марс»; ИНН 5045016560';
    },
  },
  methods: {
    handleAdvertiser() {
      this.showAd = !this.showAd;
    },
  },
};
</script>

<style scoped lang="scss">
.advertiser {
  cursor: pointer;
}
</style>
