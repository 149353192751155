<script setup lang="ts">
import MainMenu from './MainMenu.vue';
import Container from '~/components/UI/Container.vue';
import { dataLayer } from '~/shared/utils/dataLayer';

const mobileBtn = useTemplateRef('mobile-nav-menu');
const mobileMenu = useTemplateRef('mobile-menu');

const desktopWidth = 1200;

const showNavMobileMenu = () => {
  const btn = mobileBtn.value;
  const menu = mobileMenu.value;

  btn?.classList.toggle('header__burger--opened');
  menu?.classList.toggle('header__menu--opened');
  dataLayer('header_menu', 'open_menu_burger');
};

const hideNavOnRedirect = (value: string) => {
  dataLayer('header_menu', value);
  if (window.innerWidth < desktopWidth) {
    showNavMobileMenu();
    dataLayer('header_menu', 'close_menu_burger');
  }
};
</script>

<template>
  <header ref='header' class='header'>
    <Container class='header__container'>
      <NuxtLink
        to='/'
        class='header__logo header__link'
        rel='nofollow'
        @click="() => dataLayer('header_menu', 'Kitekat')"
      >
        <img
          src='~/assets/images/logo.svg'
          class='d-block img-fluid'
          alt='Логотип Kitekat'
        />
      </NuxtLink>

      <NuxtLink to='/' rel='nofollow' class='header__info' @click="() => dataLayer('header_menu', 'Kitekat')">
        <img
          src='~/assets/images/main-cat-badges/svg/25.svg'
          class='d-block img-fluid'
          alt='25 лет в России'
        />
      </NuxtLink>

      <button
        ref='mobile-nav-menu'
        class='header__burger'
        type='button'
        @click='showNavMobileMenu'
      >
        <span class='visually-hidden'>Открыть меню</span>
      </button>

      <div ref='mobile-menu' class='header__menu'>
        <div class='header__menu-inner'>
          <nav class='header__nav'>
            <MainMenu @hide-nav-on-redirect='hideNavOnRedirect' />
          </nav>
          <Social class='header__social social--in-header' />
        </div>
      </div>
    </Container>
  </header>
</template>

<style lang="scss" scoped>
.header {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  padding-bottom: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 5;
}

.header__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "info logo burger";
  align-items: center;

  @media (min-width: $breakpoint-xl) {
    display: flex;
    justify-content: space-between;
  }
}

.header__logo {
  width: 8.5rem;
  align-self: start;
  justify-self: center;
  color: inherit;
  text-decoration: none;
  flex-grow: 0;
  flex-shrink: 0;
  grid-area: logo;
  transform: translateX(0.5rem);
  position: relative;
  z-index: 1;

  @include focus-visible-default;

  @media (min-width: $breakpoint-xl) {
    justify-self: start;
    transform: none;
  }

  @media (min-width: $breakpoint-xxl) {
    width: 10rem;
  }
}

.header__info {
  width: 5rem;
  color: inherit;
  text-decoration: none;
  flex-grow: 0;
  flex-shrink: 0;
  grid-area: info;

  @include focus-visible-default;

  @media (min-width: $breakpoint-xxl) {
    width: 8rem;
  }
}

.header__burger {
  display: block;
  width: 1.5rem;
  aspect-ratio: 1;
  padding: 0;
  background: url("~/assets/images/stack.svg#burger") center / contain no-repeat;
  border: none;
  cursor: pointer;
  grid-area: burger;
  justify-self: end;
  color: inherit;
  text-decoration: none;
  position: relative;
  z-index: 1;

  @include focus-visible-default;

  &--opened {
    background: url("~/assets/images/stack.svg#menu-close");
  }

  @media (min-width: $breakpoint-xl) {
    display: none;
  }
}

.header__menu {
  @media (max-width: ($breakpoint-xl - 1)) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background: var(--color-primary);
    padding: 4.5rem 0.75rem 2rem;
    flex-direction: column;
    row-gap: 32px;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform, visibility;
    transition-duration: .3s;
    transition-timing-function: ease-in;
    &--opened {
      transform: translateX(0);
      transition-timing-function: ease-out;
      visibility: visible;
    }
  }
}

.header__menu-inner {
  @media (max-width: ($breakpoint-xl - 1)) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
    overflow-y: auto;
  }
}

.header__social {
  margin-top: 1rem;
  margin-bottom: 0;

  @media (min-width: $breakpoint-xl) {
    display: none;
  }
}
</style>
