<template>
  <div class='cat-ears'>
    <div class='cat-ears__layer cat-ears__layer--head'>
      <div class='cat-ears__layer cat-ears__layer--fur' />
      <div class='cat-ears__layer cat-ears__layer--ear-right' />
      <div class='cat-ears__layer cat-ears__layer--ear-left' />
      <div class='cat-ears__layer cat-ears__layer--forehead' />
    </div>
  </div>
</template>

<style scoped lang="scss">
.cat-ears {
  overflow: hidden;
  width: 320px;
  aspect-ratio: 2.53/1;

  @media (min-width: $breakpoint-md) {
    width: auto;
    height: 150%;
  }
}

.cat-ears__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;

  &--head {
    animation: shopsCatHeadAnimation 4s infinite;
  }

  &--fur {
    background-image: url("~/assets/images/cat-ears/fur.webp");
  }

  &--ear-right {
    transform-origin: center bottom;
    animation: shopsCatAnimationLeft 8s infinite;
    background-image: url("~/assets/images/cat-ears/ear-right.webp");
  }

  &--ear-left {
    transform-origin: center bottom;
    animation: shopsCatAnimationRight 8s infinite;
    background-image: url("~/assets/images/cat-ears/ear-left.webp");
  }

  &--forehead {
    background-image: url("~/assets/images/cat-ears/forehead.webp");
  }
}

@keyframes shopsCatAnimationLeft {
  0%,
  10%,
  12%,
  30%,
  70%,
  80%,
  100% {
    transform: rotate(0);
  }
  11%,
  40% {
    transform: rotate(-4deg);
  }
  71% {
    transform: skewX(-4deg);
  }
}

@keyframes shopsCatAnimationRight {
  0%,
  10%,
  13%,
  30%,
  72%,
  80%,
  100% {
    transform: rotate(0);
  }
  12%,
  40% {
    transform: rotate(4deg);
  }
  73% {
    transform: skewX(4deg);
  }
}

@keyframes shopsCatHeadAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(2%);
  }
}
</style>
