<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { usePageStore } from '~/store/page/page';

import Footer from '~/components/Footer/Footer.vue';
import Header from '~/components/Header/Header.vue';
import Preloader from '~/components/UI/Preloader.vue';

const pageStore = usePageStore();
const { isLoading } = storeToRefs(pageStore);
</script>

<template>
  <div class='page'>
    <Header />
    <main class='page__content'>
      <slot />
    </main>
    <Footer />
    <transition name='preloader-fade'>
      <Preloader v-if='isLoading' class='page__preloader' />
    </transition>
  </div>
</template>

<style scoped lang="scss">
.page {
  min-width: 320px;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family:
    var(--font-handbook),
    system-ui,
    -apple-system,
    "Segoe UI",
    "Helvetica Neue",
    "Noto Sans",
    "Liberation Sans",
    Arial,
    sans-serif;
  font-weight: 400;
  color: var(--text-base-color);
  line-height: 1.25;
  position: relative;

  @media (min-width: $breakpoint-lg) {
    background-image: url("/assets/images/screen-bg.webp");
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
}

.page__content {
  flex-grow: 1;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.page__preloader {
  position: fixed;
  top: -4%;
  right: 0;
  bottom: -4%;
  left: 0;
  transform: skewY(-2deg);
  z-index: 600;
}

.preloader-fade-enter-active,
.preloader-fade-leave-active {
  transition: transform 0.5s ease-in;
}
.preloader-fade-enter,
.preloader-fade-leave-to {
  transform: translateY(100%);
}
</style>
