<script setup lang="ts">
import FooterNav from '~/components/Footer/FooterNav.vue';
import Advertiser from '~/components/Advertiser/Advertiser.vue';
import CatEars from '~/components/Cat/CatEars.vue';
import Container from '~/components/UI/Container.vue';

const route = useRoute();

const isHomePage = computed(() => route.fullPath === '/');
</script>

<template>
  <footer class='footer'>
    <Container class='footer__container'>
      <noindex>
        <FooterNav class='footer__nav' />
      </noindex>
      <Advertiser class='footer__advertiser' />
      <FooterPhone class='footer__phone' />
      <Social class='footer__social' />
      <FooterCopy class='footer__copy' />
    </Container>

    <CatEars v-if='isHomePage' class='footer__cat' />
  </footer>
</template>

<style lang="scss">
.footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: var(--white);
  position: relative;

  @media (min-width: $breakpoint-md) {
    padding-bottom: 1rem;
  }
}

.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 1;

  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-areas:
      "menu social"
      "advertiser social"
      "phone social"
      "copy social";
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
  }
}

.footer__nav {
  @media (min-width: $breakpoint-md) {
    grid-area: menu;
  }
}

.footer__advertiser {
  @media (min-width: $breakpoint-md) {
    grid-area: advertiser;
  }
}

.footer__phone {
  margin-top: 0.5rem;
  margin-bottom: 0;

  @media (min-width: $breakpoint-md) {
    grid-area: phone;
  }
}

.footer__social {
  margin: 0;

  @media (min-width: $breakpoint-md) {
    grid-area: social;
  }
}

.footer__copy {
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: $breakpoint-md) {
    grid-area: copy;
    margin-top: -0.625rem;
  }
}

.footer__cat {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  pointer-events: none;
}
</style>
