<script lang="ts" setup>
</script>

<template>
  <div class='preloader'>
    <div class='preloader__dot preloader__dot_1' />
    <div class='preloader__dot preloader__dot_2' />
    <div class='preloader__dot preloader__dot_3' />
  </div>
</template>

<style lang="scss" scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);

  &__dot {
    width: 10px;
    height: 10px;
    background-color: var(--dark-green);
    border-radius: 100%;
    margin: 10px;
    animation: preloaderDotAnimation 0.5s step-start both infinite;
    &_1 {
      animation-delay: 0s;
    }
    &_2 {
      animation-delay: 0.1s;
    }
    &_3 {
      animation-delay: 0.2s;
    }
  }
}

@keyframes preloaderDotAnimation {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
