<script setup lang="ts">
import { useRoute } from 'vue-router';

const emit = defineEmits(['hideNavOnRedirect']);
const route = useRoute();
</script>

<template>
  <ul class='main-menu'>
    <li class='main-menu__item'>
      <NuxtLink
        to='/vashi-istorii'
        class='main-menu__link'
        @click='() => emit("hideNavOnRedirect", "Ваши истории")'
      >
        Ваши истории
      </NuxtLink>
    </li>
    <li class='main-menu__item'>
      <NuxtLink
        to='/korma'
        class='main-menu__link'
        :class="{ 'active-korma-detail': route.path.includes('korma') }"
        @click='() => emit("hideNavOnRedirect", "Рационы")'
      >
        Рационы
      </NuxtLink>
    </li>
    <li class='main-menu__item'>
      <NuxtLink
        to='/faq'
        class='main-menu__link'
        @click='() => emit("hideNavOnRedirect", "FAQ")'
      >
        FAQ
      </NuxtLink>
    </li>
    <li class='main-menu__item'>
      <NuxtLink
        to='/gde-kupit'
        class='main-menu__link'
        @click='() => emit("hideNavOnRedirect", "Купить")'
      >
        Купить
      </NuxtLink>
    </li>
    <li class='main-menu__item'>
      <NuxtLink
        to='/articles'
        class='main-menu__link'
        @click='() => emit("hideNavOnRedirect", "Полезные советы")'
      >
        Полезные советы
      </NuxtLink>
    </li>
    <li class='main-menu__item'>
      <NuxtLink
        to='/experts'
        class='main-menu__link'
        @click='() => emit("hideNavOnRedirect", "Эксперты")'
      >
        Эксперты
      </NuxtLink>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.main-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: var(--font-dom-casual);
  font-size: 1.5rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;

  @media (min-width: $breakpoint-lg) {
    font-size: 1rem;
  }

  @media (min-width: $breakpoint-xl) {
    flex-direction: row;
    font-size: 1.25rem;
  }

  @media (min-width: $breakpoint-xxl) {
    font-size: 1.5rem;
  }
}

.main-menu__link {
  display: block;
  padding: 0.25rem 0.75rem;
  color: var(--white);
  text-decoration: none;
  transition:
    opacity 0.2s ease,
    border-color 0.2s ease;
  border: 2px solid transparent;
  border-radius: 2rem;

  &:hover {
    opacity: 0.8;
  }

  &:focus-visible {
    outline: 3px solid color-mix(in srgb, currentColor 50%, transparent);
    outline-offset: 0.25rem;
    text-decoration: none;
  }

  &.router-link-active,
  &.active-korma-detail{
    border-color: rgba(255, 255, 255, 0.5);
    cursor: default;
    pointer-events: none;
  }
}
</style>
